import data from '.';
import { MarqueeProps } from '../../../components/MarqueV2';
import { GIFS_AND_USER } from './gifs';
import { NOTES } from './notes';
import { USERS } from './testimonialsv2';

const repeatArray = <T>(arr: T[], times: number) => {
  const result = [];
  for (let i = 0; i < times; i++) {
    result.push(...arr);
  }
  return result;
};

export const products: MarqueeProps['data'] = data.gifts.map((rest) => ({
  type: 'image',
  ...rest,
}));

const gifs: MarqueeProps['data'] = GIFS_AND_USER.map((gif) => ({
  type: 'gif',
  url: gif.url,
  alt: gif.alt,
  user: {
    image: gif.user.image,
    name: gif.user.firstName,
  },
}));

export const notes: MarqueeProps['data'] = NOTES.map((rest) => ({
  type: 'note',
  user: {
    image: rest.user.image,
    name: rest.user.firstName,
  },
  html: rest.html,
  palette: rest.palette,
}));

const imagesWithAvatar: MarqueeProps['data'] = [
  {
    type: 'image',
    alt: 'beach',
    image:
      'https://s3-alpha-sig.figma.com/img/9210/3e45/4b93e271bdd5225c6693b1b4953ddc59?Expires=1676851200&Signature=FiIdlvQzbfsht1i~vlp~JEWUdS4pdmBUmhszLU4K-FQRa6JzeVFLzCNwIfCc852kZ3t4R0lrrIkWFvbj0eLJZgZIoZtAkHpYObaVJi-m-qO7Ch1uFcKhbaXeQOYZnHvrl-Q-JVGErGt2mF98Qk65YAxLkb8I4MOO46T7Vsc2WaEovhHRlu76I1LhA36ldVlXXDo4dNNU6c-wnXiFaY4uvwxtQ8l1~HH1C04VTVplK~z3v8YYR-Gg6ShoWgy5h~S~Vq-lXxEzKy6R8fHJzvIEtJ8RG801jif0hmsBYg9KinNb9EwWQ8xeUU9GCgD2VmitjXYu~CAo4-kZXm-xoaZ00Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    user: {
      name: USERS.antonio.firstName,
      image: USERS.antonio.image,
    },
  },
];

const shuffleArray = <T>(array: T[]) => array.sort(() => Math.random() - 0.5);

export const all_type_of_carousel_data = shuffleArray([
  ...notes,
  ...gifs,
  ...products,
  // ommited because it doesn't have picture and text
  // ...imagesWithAvatar,
]);

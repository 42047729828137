import React from 'react';
import { FunctionComponent } from 'react';

import { Snackbar, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close'; // Importing the Close icon

interface AnnouncmentToastProps {
  open: boolean;
  onClose: () => void;
  announcmentText: React.ReactNode;
  position?: 'bottom' | 'top';
}

// styles
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // ctner the text
      textAlign: 'center',
      fontFamily: 'Roboto !important',
      fontWeight: 300,
      alignItems: 'center !important',
      '& .MuiAlert-action': {
        paddingTop: 10, // Adjust padding here if needed,
        alignSelf: 'flex-start',
      },
    },
    closeButton: {
      alignSelf: 'flex-start',
      backgroundColor: 'transparent', // Transparent background
    },
  })
);

export const AnnouncmentToast: FunctionComponent<AnnouncmentToastProps> = ({
  open,
  onClose,
  announcmentText,
  position = 'top',
}) => {
  const classes = useStyles();
  return (
    <Snackbar anchorOrigin={{ horizontal: 'center', vertical: position }} open={open}>
      <Alert
        className={classes.root}
        variant='filled'
        severity='info'
        action={
          <IconButton size='small' className={classes.closeButton} onClick={onClose}>
            <CloseIcon fontSize={'medium'} style={{ color: 'white', marginTop: '-4px' }} />
          </IconButton>
        }
      >
        {announcmentText}
      </Alert>
    </Snackbar>
  );
};

import analytics from '../helpers/analytics';

interface ITracker<T = Record<string, unknown>> {
  trackEvent(name: string, payload?: T): void;
  trackPage(name: string, payload: T): void;
}

export class SegmentTracker implements ITracker {
  trackEvent(name: string, payload?: Record<string, any>): void {
    analytics.track(name, payload);
  }
  trackPage(name: string, payload: Record<string, any>) {
    analytics.page(name, payload);
  }
}

export class TrackerSpy implements ITracker {
  trackEventCalls = 0;
  trackEvent(name: string, payload: Record<string, unknown>): void {
    console.log('tracking event', { name, payload });
    this.trackEventCalls++;
  }
  trackPage(name: string, payload: Record<string, unknown>): void {
    throw new Error('Method not implemented.');
  }
}

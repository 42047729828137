import { USERS } from './testimonialsv2';

export const GIFS_AND_USER = [
  {
    url: 'https://media3.giphy.com/media/IwAZ6dvvvaTtdI8SD5/giphy.gif?cid=ecf05e47jjbmec9ude47jfy8kwqfq1waofkyw4gtbzcyv0ek&rid=giphy.gif&ct=g',
    alt: 'Excited Season 2 GIF By The Office',
    user: USERS.tony,
  },
  {
    url: 'https://s.yimg.com/ny/api/res/1.2/6uXh6OwICf_qZabJPbDkDg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTU0MDtjZj13ZWJw/https://media.zenfs.com/en-us/homerun/etonline.tv/bae20df8f442036a4978234fb8387b0a',
    alt: 'Taylor swift dancing',
    user: USERS.matt,
  },
  {
    url: 'https://media.giphy.com/media/GStLeae4F7VIs/giphy.gif',
    alt: 'Birthday',
    user: USERS.ryan,
  },
  // {
  //   url: 'https://media1.giphy.com/media/13oWrv5RvpUIs77KlF/giphy.gif?cid=ecf05e47awyily4g6vg0du3v7xe4iluxb8t9hvmr79776y62&rid=giphy.gif&ct=g',
  //   alt: 'New baby',
  //   user: USERS.sarah,
  // },
];

import aesop from '../../../images/marketing/curated-gifts/aesop.jpeg';
import airbnb from '../../../images/marketing/curated-gifts/airbnb.jpeg';
import benAndJerrys from '../../../images/marketing/curated-gifts/ben-and-jerrys.png';
import brightland from '../../../images/marketing/curated-gifts/brightland.jpeg';
import burlapAndBarrel from '../../../images/marketing/curated-gifts/burlap-and-barrel.jpeg';
import goldbelly from '../../../images/marketing/curated-gifts/goldbelly.jpeg';
import graceFarmsFoods from '../../../images/marketing/curated-gifts/grace-farms-foods.jpeg';
import heyday from '../../../images/marketing/curated-gifts/heyday.jpeg';
import lokumTurkishDelights from '../../../images/marketing/curated-gifts/lokum-turkish-delights.jpeg';
import momaDesignStore from '../../../images/marketing/curated-gifts/moma-design-store.jpg';
import myKudos from '../../../images/marketing/curated-gifts/my-kudos.png';
import nike from '../../../images/marketing/curated-gifts/nike.jpeg';
import portOfMokha from '../../../images/marketing/curated-gifts/port-of-mokha.jpeg';
import barkuterie from '../../../images/marketing/curated-gifts/barkuterie.png';
import uberEats from '../../../images/marketing/curated-gifts/uber-eats.jpeg';
import { Gift } from '../../common/types';

export const marketplace_products: Gift[] = [
  {
    image: heyday,
    text: 'Give someone the gift of a spa day at Heyday',
    alt: 'Heyday facial',
  },
  {
    image: benAndJerrys,
    text: 'Ben & Jerrys Ice Cream... there’s nothing quite like it',
    alt: 'Ben&Jerry’s Ice Cream',
  },
  {
    image: portOfMokha,
    text: "The world's most exquisite coffee from Port of Mokha",
    alt: 'Port of Mokha coffee',
  },
  {
    image: burlapAndBarrel,
    text: 'Incredible spices from Burlap & Barrel',
    alt: 'Burlap & Barrel spices',
  },
  {
    image: airbnb,
    text: 'Gift an Airbnb giftcard and send them on their next vacation',
    alt: 'Airbnb gift card',
  },
  {
    image: momaDesignStore,
    text: 'Choose from the unique selection at the MoMA Design Store',
    alt: 'Moma Design Store selection',
  },
  {
    image: brightland,
    text: 'Nourishing olive oil & vinegar from Brightland',
    alt: 'Brightland olive oil',
  },
  {
    image: barkuterie,
    text: 'Barkuterie Boards for your four-legged friends',
    alt: 'Barkuterie',
  },
  {
    image: graceFarmsFoods,
    text: 'Coffee and cookies made by Grace Farms Foods',
    alt: 'Grace Farm Foods',
  },
  {
    image: uberEats,
    text: 'Pay for their next meal with an Uber Eats giftcard',
    alt: 'UberEats gift card',
  },
  {
    image: nike,
    text: 'Airdunks, Jordans, Air-Force 1s...Gift some fresh Nikes.',
    alt: 'Nike gift card',
  },
  {
    image: goldbelly,
    text: 'Get iconic foods shipped nationwide with Goldbelly',
    alt: 'Goldbelly gift card',
  },
  {
    image: lokumTurkishDelights,
    text: 'Heavenly sweets from Lokum Turkish Delights',
    alt: 'Lokum Turkish Santa Barbara',
  },
  {
    image: aesop,
    text: 'Luxurious skincare products from Aesop',
    alt: 'Aesop gift',
  },
  // ommited because it doesn't look good on the page
  // {
  //   image: myKudos,
  //   text: 'My Kudos - the first 100% cotton-lined, disposable diapers.',
  //   alt: 'Kudos diapers',
  // },
];

export const slack_products: Record<string, Gift> = {
  goldBelly: marketplace_products[11],
  benAndJerrys: marketplace_products[1],
  uberEats: marketplace_products[9],
  airbnb: marketplace_products[4],
};

// export const ObjectKeys = <T>(o: T) => Object.keys(o as Record<string, unknown>) as
// export const ObjectValues = <T>(o: T) => Object.values(o) as (keyof T)[];
export const ObjectEntries = <T>(o: T) => Object.entries(o) as [keyof T, T[keyof T]][];

export default marketplace_products;

import React from 'react';
import styled from 'styled-components';

interface Props {
  pictures: { url: string; alt: string }[];
  maxWidth?: number;
}

const PictureContainer = styled.div<{ borderWidth: number; picture: string; index: number }>`
  position: absolute;
  border-radius: 12px;
  background: url(${(props) => props.picture});
  background-size: cover;
  background-position: center;
  width: 65px;
  height: 85px;
  border: 2px solid rgba(255, 190, 167, 1);
  bottom: ${(props) => props.index * -4}px;
  right: ${(props) => props.index * -4}px;
  @media (min-width: 768px) {
    border: ${(props) => props.borderWidth}px solid rgba(255, 190, 167, 1);
    height: 160px;
    width: 124px;
    bottom: ${(props) => props.index * -8}px;
    right: ${(props) => props.index * -8}px;
  }
`;

export const StackOfPictures: React.FC<Props> = ({ pictures }) => {
  return (
    <div style={{ position: 'relative' }}>
      {pictures.map((picture, index) => {
        const last = index === pictures.length - 1;
        return (
          <PictureContainer
            role={'img'}
            aria-label={picture.alt}
            title={picture.alt}
            borderWidth={last ? 2 : 4}
            key={index}
            picture={picture.url}
            index={index}
            style={{
              zIndex: pictures.length - index,
            }}
          ></PictureContainer>
        );
      })}
    </div>
  );
};

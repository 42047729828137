import * as React from 'react';
import dompurify from 'isomorphic-dompurify';

import styled from 'styled-components';

const Layout = styled.section<{ reverse: boolean }>`
  z-index: 2;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
  row-gap: 48px;
  justify-content: space-around;
  @media (min-width: 1024px) {
    justify-content: space-between;
  }
  max-width: calc(579px + 145px + 416px);
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
`;

const ImageContainer = styled.div`
  max-width: 579px;
`;

const CopyContainer = styled.div`
  max-width: 491px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  color: ${({ theme }) => theme.colors.gray100};
  padding: 0 12px;
  h1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    @media (min-width: 1024px) {
      font-size: 64px;
      line-height: 70px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  p {
    line-height: 26px;
    text-align: center;
    font-weight: 300;
    font-weight: 390;
    color: ${({ theme }) => theme.colors.textColorCopy};
    line-height: 26px;
    font-size: 16px;
    @media (min-width: 1024px) {
      font-size: 18px;
      text-align: left;
    }
  }
  a {
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    text-decoration-line: underline;
    color: #e5a619;
    text-align: center;
    @media (min-width: 1024px) {
      text-align: left;
    }
  }
`;

interface ImageCopyProps extends React.HTMLAttributes<HTMLDivElement> {
  // render props
  render: () => React.ReactNode;
  reverse?: boolean;
  copyProps: {
    title: string;
    subtitle: string;
    linkhref: string;
  };
}

export function ImageAndCopySection(props: ImageCopyProps) {
  const { render = () => null, copyProps, reverse = false, ...rest } = props;
  const { title, subtitle, linkhref } = copyProps;
  const createMarkup = () => {
    return { __html: dompurify.sanitize(title) };
  };
  return (
    <Layout reverse={reverse} {...rest}>
      <CopyContainer>
        <h1 dangerouslySetInnerHTML={createMarkup()} />
        <p>{subtitle}</p>
        {linkhref && <a href={linkhref}>Learn More</a>}
      </CopyContainer>
      <ImageContainer>{render()}</ImageContainer>
    </Layout>
  );
}

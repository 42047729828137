import { USERS } from './testimonialsv2';

const PALETTES = {
  brown: {
    main: '#88502c',
    contrastText: '#c9ba9e',
  },
  pink_peach: {
    main: '#ffbea7',
    contrastText: '#867b4f',
  },
  yellow_kulfi: {
    main: '#ebbf7a',
    contrastText: '#ffffff',
  },
  blueish: {
    main: '#c6dae0',
    contrastText: '#3f431f',
  },
  gray: {
    main: '#b8beb6',
    contrastText: '#45525c',
  },
};

export const NOTES = [
  {
    html: "<b>Thank you for being real - </br> it's refreshing. </br> Happy birthday!!</b>",
    user: USERS.anne,
    palette: PALETTES.brown,
  },
  {
    html: '<b>You light up every room you enter, and lift up every person you meet. Keep being you!</b>',
    user: USERS.jordan,
    palette: PALETTES.gray,
  },
  {
    // html: '<b style >Just my annual reminder to say: YOU ROCK! Happy Birthday, you ray of sunshine!</b>' that has smaller fontsize
    html: `<b style="font-size: 1.2rem">Just my annual reminder to say: YOU ROCK! Happy Birthday, you ray of sunshine!</b>`,
    user: USERS.daniela,
    palette: PALETTES.pink_peach,
  },
  {
    html: "<b>Congrats on another trip around the sun! Can't wait to celebrate with you!</b>",
    user: USERS.morgan,
    palette: PALETTES.blueish,
  },
  {
    html: "Can't wait for nightly </br> check-ins every day next <br />year ❤️",
    user: USERS.tanya,
    palette: PALETTES.yellow_kulfi,
  },
];

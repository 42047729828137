import React, { FC } from 'react';
import styled from 'styled-components';

import LinkButton from '../../components/LinkButton';

import contributorVideo from '../../videos/contributor-animation.mp4';

import useFormFactors from '../../helpers/useFormFactors';

import { START_NOW_LINK } from '../../constants/links';

import ipad_portrait from '../../images/note_in_ipad.svg';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;
  z-index: 1;
  max-width: 400px;
  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 32px 48px;
    justify-content: center;
    margin: 0;
    max-width: initial;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 40px 48px;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-width: initial;
  }
`;

const Video = styled.video`
  align-self: center;
  margin: 24px 0 0 0;
  width: 100%;
  -webkit-appearance: none;
  box-shadow: #f5f0e1 8px 8px 0px 0px;
  border-radius: 8px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 0;
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 0;
    width: 50%;
    max-width: 512px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 24px 0 0 0;
    padding: 0 0 0 48px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 24px 0 0 0;
    padding: 0 0 0 48px;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 24px 0;
    text-align: left;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  padding: 8px 24px 0 24px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 500px;
    padding: 0;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 500px;
    font-size: 64px;
    line-height: 70px;
    padding: 8px 0 0 0;
    text-align: left;
  }
`;

const List = styled.ol`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 48px 16px 0 16px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 24px 24px 0 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
  }
`;

const CreateLink = styled(LinkButton)`
  margin: 48px 0;
  width: 100%;
  font-size: 18px;
  font-weight: normal;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 24px 0 0 0;
    max-width: 180px;
    font-weight: normal;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 32px 0 0 0;
    max-width: 186px;
    font-weight: normal;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  ctaText?: string;
  ctaLink?: string;
}

const HowItWorks: FC<Props> = ({ ctaText = 'Start now', ctaLink = START_NOW_LINK, ...props }) => {
  const formFactors = useFormFactors();

  const renderTitles = () => {
    return (
      <>
        <Title>How it Works</Title>
        <Heading>A delightful experience for all</Heading>
      </>
    );
  };

  const renderImage = () => {
    // ipad
    return <img src={ipad_portrait} alt='note taking on ipad' />;
  };

  const renderVideo = () => {
    return (
      <Video autoPlay loop muted playsInline>
        <source src={contributorVideo} type='video/mp4' />
        Sorry, your browser does not support embedded videos.
      </Video>
    );
  };

  const renderListLink = () => {
    return (
      <>
        <List type='1'>
          <li>Enter basic info for the group card</li>
          <li>Customize with a theme, photos, videos and more</li>
          <li>Select a gift for the group to split</li>
          <li>Invite friends to contribute</li>
          <li>Share with the recipient and make their day</li>
        </List>
        <CreateLink href={ctaLink}>{ctaText}</CreateLink>
      </>
    );
  };

  return (
    <Layout {...props}>
      {formFactors.mobile ? (
        <>
          {renderTitles()}
          <Body>
            {renderVideo()}
            {/* {renderImage()} */}
            {renderListLink()}
          </Body>
        </>
      ) : (
        <>
          {renderVideo()}
          {/* {renderImage()} */}
          <Body>
            {renderTitles()}
            {renderListLink()}
          </Body>
        </>
      )}
    </Layout>
  );
};

export default HowItWorks;

import React, { FC } from 'react';
import { motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';

import { theme } from '../styles/theme';

import Background from '../components/Background';
import Header from '../components/Header2';
import Join from '../sections/Join';
import CuratedGifts from '../sections/marketing/CuratedGifts';
import HowItWorks from '../sections/marketing/HowItWorks';
import LoveIllume from '../sections/marketing/LoveIllume';
import MeaningfullGroupGifts from '../sections/marketing/MeaningfullGroupGifts';
import { StackOfPictures } from '../components/StackOfPortraits';
import { EVENT_TRACKS } from '../constants/eventTracks';
import { getMainAppFullPath } from '../constants/getUrl';
import { CREATE_CARD_LINK, SEND_FREE_GROUPCARD_LINK } from '../constants/links';
import { PORTRAIT_URLS } from '../data/portraits';
import { stepByStepUnordered } from '../data/stepByStepData';
import { SegmentTracker } from '../domain/Tracker';
import initiatorEditCard from '../images/initiator_card.png';
import chooseGift from '../images/initiator_choosegift.png';
import man_with_glasses from '../images/man_with_glasses.png';
import maxGift from '../images/max_group_gift.png';
import FooterNew from '../sections/common/FooterWithoutFormFactorHook';
import { ImageAndCopySection } from '../sections/ImageAndCopy';
import Companies from '../sections/marketing/Companies';
import { all_type_of_carousel_data } from '../sections/marketing/data/products_and_notes_carousel_data';
import { testimonialsV2 } from '../sections/marketing/data/testimonialsv2';
import { B2B_PRICING_DATA } from '../sections/pricing/data/B2B_PRICING_DATA';
import PricingSection from '../sections/pricing/PricingSection';
import { TitledBanner } from '../sections/TitledBanner';
import { HowItWorksStepByStep } from './splitanygifts';
import useFormFactors from '../helpers/useFormFactors';
import { AnnouncmentToast } from '../components/announcement-toast/AnnouncementToast';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const MaxGroupGiftContainer = styled.div`
  position: relative;
`;

const ImageAndShadowContainer = styled.div<{ shadowColor: string }>`
  img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }
  .shadow {
    width: 100%;
    height: 100%;
    background: ${(props) => props.shadowColor || 'rgba(0, 0, 0, 0.2)'};
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: -1;
    border-radius: 8px;
  }
`;

export const ImageAndShadow = ({
  src,
  alt,
  shadowColor,
}: {
  src: string;
  alt: string;
  shadowColor: string;
}) => {
  return (
    <ImageAndShadowContainer shadowColor={shadowColor}>
      <img src={src} alt={alt} />
      {/* <div className='shadow'></div> */}
    </ImageAndShadowContainer>
  );
};

const StyledImageAndCopySection = styled(ImageAndCopySection)`
  margin-bottom: 90px;
  @media (min-width: 768px) {
    margin-bottom: 200px;
  }
`;

const StyledPricingSection = styled(PricingSection)`
  margin-top: 100px;
`;

const flippedTexts = ['Celebrate', 'Thank', 'Support'];

const StyledCompanies = styled(Companies)`
  margin-top: 53px;
  padding-top: 0px;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const StyledHeader = styled(Header)`
  @media (min-width: 768px) {
    padding: 0 calc(24px + 24px);
  }
`;

const StyledCuratedGifts = styled(CuratedGifts)`
  margin-bottom: 85px;
  @media (min-width: 768px) {
    margin-bottom: 180px;
  }
`;

const Homepage: FC = () => {
  const { mobile } = useFormFactors();
  const [showAnnouncement, setShowAnnouncement] = React.useState(true);
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating.'
        }
      />
      <Layout>
        <AnnouncmentToast
          announcmentText={
            'Major Announcement: we are teaming up with Kudoboard to ensure you have access to a terrific group card experience. Illume.com will no longer be accessible after December 26th, 2023. Please download any notes before then if you’d like to save them.  For a free upgrade on Kudoboard.com, create your first Kudoboard before January 31st & email illume@kudoboard.com with the subject line “upgrade.”'
          }
          open={showAnnouncement}
          onClose={() => setShowAnnouncement(false)}
        />
        <Background showTopBlob={false} showBottomBlob={true} showMiddleBlob={false} />

        <StyledHeader />

        <MeaningfullGroupGifts
          flippedTextDirection='right'
          headline='Easier.'
          flippingTexts={flippedTexts}
          showCta={!mobile}
          showCount={!mobile}
        />
        <StyledCompanies withSparkles text='Trusted by consumers & teams' />
        <StyledCuratedGifts
          data={all_type_of_carousel_data}
          title='Celebrate together. Anywhere.'
          subtitle={''}
          ctaAction={{
            onClick: () => {
              window.location.href = getMainAppFullPath('/create/info');
            },
            name: 'Create a group card',
          }}
          secondaryCtaAction={{
            onClick: () => {
              window.location.href = CREATE_CARD_LINK;
            },
            name: 'Start a group gift',
          }}
        />
        <TitledBanner />

        <StyledImageAndCopySection
          copyProps={{
            title: 'Add unlimited contributors',
            subtitle: mobile
              ? 'Stand out with a gift from our curated marketplace. We’ve done all the research so you don’t have to...you can’t go wrong!'
              : 'We take the hassle out of long-distance celebration. With our dynamic group cards, you can express any feeling or mark any occasion together, no matter where you are.',
            linkhref: getMainAppFullPath('/create/info'),
          }}
          render={() => (
            <MaxGroupGiftContainer style={{ marginLeft: 10, marginRight: 10, marginBottom: 24 }}>
              <ImageAndShadow src={maxGift} alt='illume card dashboard' shadowColor='#c6dae0' />
              <div
                style={{
                  position: 'absolute',
                  bottom: mobile ? -20 : -40,
                  right: mobile ? -10 : -20,
                }}
              >
                <StackOfPictures
                  pictures={PORTRAIT_URLS.map((p) => ({ alt: p.name, url: p.src }))}
                />
              </div>
            </MaxGroupGiftContainer>
          )}
        />

        <StyledImageAndCopySection
          reverse={true}
          copyProps={{
            title: 'Express yourself effortlessly',
            subtitle:
              'Customize your group card with photos, GIFs, videos, and more. Our note suggestion library is here to help you craft your message – which you can make public or private within the card.',
            linkhref: getMainAppFullPath('/create/info'),
          }}
          render={() => (
            <MaxGroupGiftContainer>
              <ImageAndShadow
                src={initiatorEditCard}
                alt='illume card dashboard'
                shadowColor={theme.colors.orange}
              />
            </MaxGroupGiftContainer>
          )}
        />

        <StyledImageAndCopySection
          style={{
            marginBottom: 100,
          }}
          copyProps={{
            title: 'Bulk card creation for teams',
            subtitle:
              'Stay on top of birthdays, holidays, and big moments for your team. With illume, you can create as many cards as you like by uploading a simple .csv file.',
            linkhref: getMainAppFullPath('/teams'),
          }}
          render={() => (
            <MaxGroupGiftContainer>
              <ImageAndShadow src={chooseGift} alt='illume card dashboard' shadowColor='#FFBEA7 ' />
              <img
                src={man_with_glasses}
                style={{
                  position: 'absolute',
                  bottom: -40,
                  right: -20,
                  width: 156,
                }}
                alt='Man with Glasses'
              />
            </MaxGroupGiftContainer>
          )}
        />

        <HowItWorksStepByStep
          showCta={false}
          type='unordered'
          data={stepByStepUnordered}
          stepAlign='justify-between'
          title={
            mobile ? (
              <span>
                Group gift? <br /> Piece of cake.
              </span>
            ) : (
              'Group gift? Piece of cake.'
            )
          }
        />
        <HowItWorks
          style={{ marginBottom: 100 }}
          ctaText='Try illume today'
          ctaLink={SEND_FREE_GROUPCARD_LINK}
        />
        <LoveIllume testimonials={testimonialsV2} />

        <StyledPricingSection
          title='Pricing'
          titleAlignment='center'
          id={'home-page-pricing-section'}
          pricings={B2B_PRICING_DATA}
          pricingAction={{
            onClick: (pricing) => {
              const tracker = new SegmentTracker();
              tracker.trackEvent(EVENT_TRACKS.CLICK_B2C_PRICING_PLAN.name, {
                type: pricing.pricingType,
              });
              const pathname = getMainAppFullPath('/create/select-plan');
              const urlWithPricingTypeInSearchParams = new URL(pathname);
              urlWithPricingTypeInSearchParams.searchParams.append(
                'pricingType',
                pricing.pricingType
              );
              return (window.location.href = urlWithPricingTypeInSearchParams.toString());
            },
            name: 'Get Started',
          }}
        />

        <Join />

        <FooterNew />
        {/* <FutureOfCelebrating topics={marketingData.topics} />
        <CuratedGifts gifts={marketingData.gifts} />
        <GroupCard />
        <HowItWorks />
        <Footer links={footerLinksData} />
        <FooterNew /> */}
        {/* <Footer /> */}
      </Layout>
    </ThemeProvider>
  );
};

export default Homepage;

import person_with_blue_shirt from '../images/portraits/person_with_blue_shirt.png';
import person_with_long_hair from '../images/portraits/person_with_long_hair.png';
import person_with_short_hair from '../images/portraits/person_with_short_hair.png';

export const PORTRAIT_URLS = [
  {
    src: person_with_long_hair,
    name: 'Person with long hair',
  },
  {
    src: person_with_short_hair,
    name: 'Person with short hair',
  },
  {
    src: person_with_blue_shirt,
    name: 'Person with blue shirt',
  },
];

import * as React from 'react';

import styled from 'styled-components';

interface TitledBannerProps {}

const H1 = styled.h1`
  font-size: 16px;
  font-weight: 420;
  line-height: 20px;
  margin-bottom: 12px;
  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
  letter-spacing: 0em;
  text-align: center;
  color: #e5a619;
`;

const H2 = styled.h2`
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 12px;
  line-height: 48px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 64px;
    margin-bottom: 24px;
    line-height: 70px;
  }
`;
const H3 = styled.h3`
  font-size: 16px;
  max-width: 205px;
  color: ${(props) => props.theme.colors.textColorCopy};

  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 28px;
    max-width: 1000px;
    line-height: 34px;
  }
`;

const Container = styled.div`
  z-index: 2;
  margin-top: 78px;
  margin-bottom: 56px;
  padding: 0 26px;
  color: ${(props) => props.theme.colors.gray100};
  @media (min-width: 768px) {
    margin-top: 168px;
    margin-bottom: 118px;
  }
`;

export const TitledBanner: React.FC<TitledBannerProps> = () => {
  return (
    <Container>
      <H1>The illume group card</H1>
      <H2>The way celebrations should be</H2>
      <H3>Invite individuals to contribute to a group card wherever they are.</H3>
    </Container>
  );
};

export const SlackTitledBanner: React.FC<TitledBannerProps> = () => {
  return (
    <Container>
      <H1>The illume group card</H1>
      <H2>Celebrate together. Anywhere.</H2>
      <H3>Invite individuals to contribute to a group card wherever they are.</H3>
    </Container>
  );
};
